<template>
  <v-card width="75%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Тех. процессы ожидающие распределения</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ProcessesTable :queryParams="{ status: 1 }">
        <template v-slot:name="{ process }">
          <router-link :to="'/manufacture/process-template/view?id=' + process.templateId">
            {{process.template.name}}
          </router-link>
        </template>
        <template v-slot:action="{ process }">
          <v-icon @click="appoint(process.id)">mdi-clipboard-list</v-icon>
        </template>
      </ProcessesTable>
    </v-card-text>
  </v-card>
</template>

<script>
import ProcessesTable from './components/ProcessesTable.vue'

export default {
  components: { ProcessesTable },
  methods: {
    appoint(id) {
      this.$router.push({
        path: '/manufacture/process-card/index',
        query: { procId: id, status: 1}
      })
    }
  }
}
</script>
